import React, { PropsWithChildren } from 'react';
import MultiCarousel, {
  ArrowProps,
  ButtonGroupProps,
  CarouselProps,
} from 'react-multi-carousel';

import 'react-multi-carousel/lib/styles.css';

const Carousel = ({
  className,
  children,
  ...props
}: PropsWithChildren<CarouselProps & { className?: string }>) => {
  return (
    <MultiCarousel className={className} {...props}>
      {children}
    </MultiCarousel>
  );
};

export default Carousel;
export type { ArrowProps, ButtonGroupProps };
