import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Book, useBooksQuery } from '@/api';
import { PrimaryButton } from '@/components/button';
import Carousel, { ArrowProps, ButtonGroupProps } from '@/components/carousel';
import NovaImage from '@/components/novaImage';

const BookItem = ({ data }: { data: Book }) => {
  const { image, title, author, href } = data;

  const handleClick = () => {
    window.open(href, '_blank');
  };

  return (
    <button
      className='group/book w-[250px] px-2 text-left'
      onClick={handleClick}
    >
      <div className='relative h-[250px] w-full'>
        <NovaImage
          src={image}
          alt={`bool-${title}`}
          fill
          className='object-contain duration-300 group-hover/book:scale-105'
        />
      </div>
      <p className='title-20r mt-4 line-clamp-2'>{title}</p>
      <p className='title-20r mt-2 text-[#777]'>{author}</p>
    </button>
  );
};

const responsive = {
  desktopLg: {
    breakpoint: { max: 3000, min: 1280 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1280, min: 1000 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1000, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const CustomRightArrow = ({ onClick }: ArrowProps) => {
  return (
    <button
      onClick={() => onClick?.()}
      className='absolute right-2 h-14 w-14 -translate-y-1/2 duration-150 hover:scale-105'
    >
      <Image
        src='/image/landing/share-arrow-right.png'
        alt='arrow-right'
        width={56}
        height={56}
      />
    </button>
  );
};

const CustomLeftArrow = ({ onClick }: ArrowProps) => {
  return (
    <button
      onClick={() => onClick?.()}
      className='absolute left-2 h-14 w-14 -translate-y-1/2 duration-150 hover:scale-105'
    >
      <Image
        src='/image/landing/share-arrow-left.png'
        alt='arrow-left'
        width={56}
        height={56}
      />
    </button>
  );
};

const ButtonGroup = ({
  next,
  previous,
  goToSlide,
  ...rest
}: ButtonGroupProps) => {
  return (
    <div className='container absolute'>
      <CustomLeftArrow onClick={() => previous?.()} />
      <CustomRightArrow onClick={() => next?.()} />
    </div>
  );
};

const Share = () => {
  const { t } = useTranslation();
  const { data: booksData, isLoading: booksLoading } = useBooksQuery();

  return (
    <div className='section-padding container flex flex-col items-center justify-center px-14'>
      <p className='title-48b'>{t('landing.share.title')}</p>
      {booksData && booksData.data.length > 0 && (
        <Carousel
          className='my-16 h-[400px] w-full lg:my-24'
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          infinite={true}
          transitionDuration={500}
          arrows={false}
          customButtonGroup={<ButtonGroup />}
          renderButtonGroupOutside={true}
        >
          {booksData.data.map((book, index) => (
            <BookItem key={book.title} data={book} />
          ))}
        </Carousel>
      )}
      <PrimaryButton
        onClick={() => {
          window.open(
            'https://www.scbooks.com.tw/#/search/series/SC8A/1',
            '_blank'
          );
        }}
      >
        {t('landing.share.button')}
      </PrimaryButton>
    </div>
  );
};

export default Share;
